import React, { useState, useEffect, memo, useMemo } from 'react';
import { GoogleMap, OverlayView, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { Button, useMediaQuery, Typography, Box, Card, CardContent } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import theme from '../theme';
import { t } from 'i18next';
import mapSpanLogo from '../assets/images/mapSpan.png';
import mapCloseExpanLogo from '../assets/images/newMapCloseExpanIcon.png';
import closeMapIcon from '../assets/images/crossCloseMap.png';
import userLocationIcon from '../assets/images/userLocationIcon.png';
import zIndex from '@mui/material/styles/zIndex';
import InfoWindowContent from './infoWindowContent';
import img1 from '../assets/icons/Group1.png';
import img2 from '../assets/icons/Group2.png';
import img3 from '../assets/icons/Group3.png';
import img4 from '../assets/icons/Group4.png';
import img5 from '../assets/icons/Group5.png';
import img6 from '../assets/icons/Group6.png';

import { useNavigate } from 'react-router-dom';

const MapComponent = ({ store, apiKey, handleClick, onUserLocationChange, leftBoxVisible, searchedData }) => {
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { ref, inView } = useInView({ triggerOnce: true });
    const [userLocation, setUserLocation] = useState(null);
    const [locationAccessGranted, setLocationAccessGranted] = useState(true);
    const defaultLocation = { lat: 55.6712398, lng: 12.5114246 };
    const [activeMarker, setActiveMarker] = useState(null);
    const [lastClickTime, setLastClickTime] = useState(null);
    const [zoom, setZoom] = useState(11);
    const [center, setCenter] = useState(userLocation || defaultLocation);
    const kilometerRange = searchedData?.kilometer

    const markerIcons = {
        '11': [img1, img4],
        '2': [img1, img4],
        '13': [img2, img5],
        '23': [img3, img6]
    };

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,

                    });
                    setLocationAccessGranted(true);
                    setCenter({lat: position.coords.latitude,lng: position.coords.longitude,});
                    onUserLocationChange({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                (error) => {
                    console.warn('Geolocation error:', error);
                    setUserLocation(defaultLocation);
                    setLocationAccessGranted(false);
                    setCenter(defaultLocation);
                    //onUserLocationChange(defaultLocation);
                }
            );
        } else {
            setUserLocation(defaultLocation);
            setLocationAccessGranted(false);
            setCenter(defaultLocation);
            // onUserLocationChange(defaultLocation);
        }

    }, []);

    const markers = useMemo(() => {
        return store.map(storeObj => {
            // console.log('STORE_OBJ ', JSON.stringify(storeObj))
            const [lng, lat] = storeObj.geo_location
                .replace('POINT (', '')
                .replace(')', '')
                .split(' ')
                .map(Number);

            const hasSpecialDiscount = (storeObj) => (storeObj.have_special_offer) === true;

            const category = storeObj.categories.length > 0 ? storeObj.categories[0].id : '';
            const iconIndex = hasSpecialDiscount(storeObj) ? 1 : 0;
            const icon = markerIcons[category] ? markerIcons[category][iconIndex] : img1;
            // console.log("category", category);
            const categorySend = storeObj.categories.length > 0 ? storeObj.categories[0].category : 'No Categories';
            const categoryCount = storeObj.categories.length;

            return {
                id: storeObj?.id,
                name: storeObj?.name,
                position: { lat, lng },
                // categoryImageUrl: storeObj.categories.length > 0 && storeObj.categories[0].image
                // ? `https://fiind-dev.s3.amazonaws.com/${storeObj.categories[0].image}`
                // : 'path/to/your/white-background-image.png'
                categoryImageUrl: icon,
                avg_rating: storeObj?.avg_rating,
                address: storeObj?.address,
                open: storeObj?.open,
                reviews: storeObj?.total_reviews,
                categorySend,
                categoryCount,
                imgPath: `https://fiind-dev.s3.amazonaws.com/${storeObj?.profile_image}`,
                storeName: storeObj?.web_store_name,
                iconIndex,
            }
        })

    }, [store])
    
    

    const mapContainerStyle = {
        width: '100%',
        height: '450px',
        position: 'relative',
    };

    // const { isLoaded } = useLoadScript({
    //     googleMapsApiKey: apiKey,
    // });

    // if (!isLoaded) {
    //     return <div>Loading...</div>;
    // }

    //const center = userLocation || defaultLocation;

    // const CustomMarker = ({ lat, lng, imageUrl }) => {
    //     const markerStyle = {
    //         backgroundImage: `url(${imageUrl})`,
    //         backgroundSize: 'cover',
    //         borderRadius: '50%',
    //         width: '45px',
    //         height: '45px',
    //         position: 'absolute',
    //         border: '2px solid #ffbc97',
    //     };

    //     const pointerStyle = {
    //         content: '""',
    //         position: 'absolute',
    //         bottom: '-10px',
    //         left: '50%',
    //         marginLeft: '-5px',
    //         borderLeft: '5px solid transparent',
    //         borderRight: '5px solid transparent',
    //         borderTop: '10px solid #ffbc97',
    //     };

    //     return (
    //         <OverlayView
    //             position={{ lat, lng }}
    //             mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    //         >
    //             <div style={markerStyle}>
    //                 <div style={pointerStyle}></div>
    //             </div>
    //         </OverlayView>
    //     );
    // };

    const CustomMarker = memo(({ lat, lng, imageUrl }) => {
        const markerStyle = {
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'cover',
            borderRadius: '50%',
            width: '45px',
            height: '45px',
            position: 'absolute',
            border: '2px solid #ffbc97',
        };

        const pointerStyle = {
            content: '""',
            position: 'absolute',
            bottom: '-10px',
            left: '50%',
            marginLeft: '-5px',
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderTop: '10px solid #ffbc97',
        };

        return (
            <OverlayView
                position={{ lat, lng }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
                <div style={markerStyle}>
                    <div style={pointerStyle}></div>
                </div>
            </OverlayView>
        );
    });

    // const UserLocationMarker = memo(({ lat, lng }) => {
    //     const circleStyle = {
    //         backgroundColor: 'rgba(0, 0, 255, 0.5)',
    //         borderRadius: '50%',
    //         width: '25px',
    //         height: '25px',
    //         position: 'absolute',
    //         border: '2px solid #ffffff',
    //     };

    //     return (
    //         <OverlayView
    //             position={{ lat, lng }}
    //             mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    //         >
    //             <div style={circleStyle}></div>
    //         </OverlayView>
    //     );
    // });

    const UserLocationMarker = memo(({ lat, lng }) => {
        const markerIcon = {
            url: userLocationIcon,
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(20, 20),
        };

        return (
            <OverlayView
                position={{ lat, lng }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
                <Marker
                    position={{ lat, lng }}
                    icon={markerIcon}
                />
            </OverlayView>
        );
    });

    const RoundButton = () => {
        const buttonStyle = {
            position: 'absolute',
            bottom: '20px',
            left: '20px',
            width: '45px',
            height: '45px',
            borderRadius: '50%',
            backgroundColor: '#ffbc97',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
            cursor: 'pointer',
            zIndex: 12
        };

        return (
            <div style={buttonStyle} onClick={handleClick}>
                <img
                    src={leftBoxVisible ? mapSpanLogo : mapCloseExpanLogo}
                    alt="IconOne"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                />
            </div>
        );
    };

    const SmallScreenRoundButton = () => {
        const buttonStyle = {
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            width: '45px',
            height: '45px',
            borderRadius: '50%',
            backgroundColor: '#ffbc97',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
            cursor: 'pointer',
            zIndex: 7
        };

        return (
            <div style={buttonStyle} onClick={handleClick}>
                <img
                    src={closeMapIcon}
                    alt="IconOne"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                />
            </div>
        );
    };

    const LocationAccessOverlay = () => {
        const overlayStyle = {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(227, 224, 219, 0.8)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 6,
        };

        const buttonStyle = {
            padding: '10px 20px',
            borderRadius: '5px',
            backgroundColor: '#ffbc97',
            cursor: 'pointer',
            boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
            marginTop: '10px',
        };

        return (
            <div style={overlayStyle}>
                <Typography style={{ textAlign: 'center', marginBottom: '10px', fontWeight: '700' }}>
                    To get the full experience, Allow Location Service
                </Typography>

                <Button
                    size='medium'
                    variant="contained"
                    disableElevation
                    sx={{
                        width: isSmallScreen ? '80%' : '30%',
                        // height: 50,
                        borderRadius: 3,
                        color: 'white',
                        border: '2px solid white',
                        backgroundColor: theme.palette.primary.main,
                        textTransform: 'none',
                        fontWeight: 700,
                        fontSize: 15,
                    }}
                    // onClick={() => { requestLocationAccess }}
                    onClick={() => {
                        location.reload(true);
                        // requestLocationAccess()
                    }}
                >
                    {t('Allowlocationservices')}
                </Button>
            </div>
        );
    };


    const handleOnLoad = (map) => {
        const bounds = new google.maps.LatLngBounds();
        markers.forEach(({ position }) => bounds.extend(position));
        //map.fitBounds(bounds);
    };

  
    useEffect(() => {
        if (kilometerRange <= 2) {
            setZoom(14);
        } else if (kilometerRange <= 5) {
            setZoom(13);
        } else if (kilometerRange <= 10) {
            setZoom(12);
        } else if (kilometerRange <= 20) {
            setZoom(11.5);
        } else if (kilometerRange <= 50) {
            setZoom(10.5);
        } else if (kilometerRange <= 100) {
            setZoom(8);
        }
        else {
            setZoom(7);
        }
    }, [kilometerRange]);

    // useEffect(()=>{

    //     const bounds = new google.maps.LatLngBounds();
    //     markers.forEach(({ position }) => bounds.extend(position));
    //     map.fitBounds(bounds);
    // },[markers])

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const handleMarkerClick = (storeName) => {
        // const currentTime = Date.now();
        // const timeDifference = currentTime - lastClickTime;
        // if (timeDifference < 1000) {
        //     navigate(`/klinik/${storeName}`, { state: { from: 'marketPlace' } })
        // } else {
        //     setLastClickTime(currentTime);
        //     handleActiveMarker(id);
        // }

        if (storeName !== null && storeName !== undefined) {


            navigate(`/klinik/${storeName}`, { state: { from: 'marketPlace' } })
        }
    };

    return (
        <Box ref={ref} style={mapContainerStyle} >
            {inView && (
                <>
                    <GoogleMap
                        onLoad={handleOnLoad}
                        mapContainerStyle={mapContainerStyle}
                        center={center}
                        zoom={zoom}
                        options={{
                            zoomControl: false,
                            streetViewControl: false,
                        }}
                        onClick={() => setActiveMarker(null)}
                    >
                        {userLocation && (
                            <UserLocationMarker
                                lat={userLocation.lat}
                                lng={userLocation.lng}
                            />
                        )}

                        {markers.map((marker) => {
                            const { id, position, categoryImageUrl, avg_rating, reviews, address, open, categorySend, categoryCount, imgPath, storeName, iconIndex } = marker

                            console.log("iconIndex" ,iconIndex);
                            

                            return (
                                <Marker
                                    key={id}
                                    position={position}
                                    icon={{
                                        url: categoryImageUrl,
                                        scaledSize: new google.maps.Size(50, 54)
                                    }}
                                    onClick={() => handleActiveMarker(id)}
                                // onClick={() => handleMarkerClick(id, storeName)}
                                >
                                    {activeMarker === id ? (
                                        <InfoWindow
                                            position={position}
                                            onCloseClick={() => setActiveMarker(null)}
                                            options={{ headerDisabled: true, className: 'custom-info-window', }}
                                        >
                                            <InfoWindowContent
                                                categoryImageUrl={imgPath}
                                                name={marker.name}
                                                rating={avg_rating}
                                                location={address}
                                                status={open}
                                                review={reviews}
                                                category={categorySend}
                                                categoryCount={categoryCount}
                                                onClick={() => { handleMarkerClick(storeName) }}
                                            />
                                        </InfoWindow>
                                    ) : null}

                                    {/* <CustomMarker
                                        scaledSize: new google.maps.Size(50, 52),
                                      }}
                                    // onClick={() => handleActiveMarker(id)}
                                  >
                                      {/* <CustomMarker
                                        key={id}
                                        lat={position?.lat}
                                        lng={position?.lng}
                                        imageUrl={categoryImageUrl}
                                    /> */}
                                </Marker>
                            );
                        })
                        }
                        {/* {store.map(store => {
                            const [lng, lat] = store.geo_location
                                .replace('POINT (', '')
                                .replace(')', '')
                                .split(' ')
                                .map(Number);
                            // const imageUrl = `https://fiind-dev.s3.amazonaws.com/${store.image}`;
                            const categoryImageUrl = store.categories.length > 0 && store.categories[0].image
                                ? `https://fiind-dev.s3.amazonaws.com/${store.categories[0].image}`
                                : 'path/to/your/white-background-image.png';
                            return (
                                <CustomMarker
                                    key={store.id}
                                    lat={lat}
                                    lng={lng}
                                    imageUrl={categoryImageUrl}
                                />
                            );
                        })} */}
                        {isSmallScreen ?
                            <SmallScreenRoundButton />
                            :
                            <RoundButton />
                        }
                    </GoogleMap>
                    {!locationAccessGranted && <LocationAccessOverlay />}
                </>
            )}
        </Box>
    );
};

export default MapComponent;
