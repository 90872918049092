import React from 'react';
import { Card, CardContent, Typography, Box, Button, Tooltip, useMediaQuery, MenuItem, Menu, Link, Stack } from '@mui/material';
import { Rating } from 'react-simple-star-rating';
import { t } from 'i18next';
import theme from '../theme';
import infoIcon from '../assets/images/Icon(1).png';
import moment from 'moment';
import locationIcon from '../assets/images/location(1).png';
import { useNavigate } from 'react-router-dom';

const ServiceCard = ({ service, checkOption, generalData, firstRow }) => {
    // const { services } = store;
    // const servicesCount = services.length;
    const navigate = useNavigate();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const cancellationEnd = moment(service?.cancellation_offer_slot_end);
    const createdAt = moment(service?.created_at);
    const now = moment();

    const serviceTypeIds = service.service_type_ids || [];

    const matchingServiceTypes = generalData.flatMap(category =>
        category.service_types.filter(serviceType =>
            serviceTypeIds.includes(serviceType.id)
        )
    );

    const isToday = cancellationEnd.isSame(now, 'day');
    const isTomorrow = cancellationEnd.isSame(now.add(1, 'day'), 'day');

    let displayText;
    if (isToday) {
        displayText = "Today";
    } else if (isTomorrow) {
        displayText = "Tomorrow";
    } else {
        displayText = cancellationEnd.format('ddd D/MM');
    }

    const timeDisplay = cancellationEnd.format('HH:mm');

    const tooltipContent = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {matchingServiceTypes.length > 0 ? (
                matchingServiceTypes.map((item, index) => (
                    <div key={index} style={{ padding: '5px' }}>
                        {item.service_type}
                    </div>
                ))
            ) : (
                <div style={{ padding: '5px' }}>No services available</div>
            )}
        </div>
    );

    return (
        <Card sx={{ width: isSmallScreen ? 'auto' : 'auto', height: 230, padding: 0, flexDirection: 'column', borderRadius: 3, boxShadow: 4 }}>

            <CardContent sx={{ flexDirection: 'column', height: '100%', padding: 0 }}>

                <Stack flex={1} flexDirection={'row'} justifyContent={'space-between'} style={{ display: 'flex', flexDirection: 'row', }}>

                    <Box sx={{ height: '50%', padding: 1, pb: 0, overflow: 'hidden', maxWidth: matchingServiceTypes.length > 0 ? '80%' : '85%', pr: 0 }}>
                        <Typography variant="body1" noWrap sx={{
                            fontWeight: 'bold', overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '0.85rem',
                            color: '#000000',
                            pr: 0
                        }}>
                            {service.name}
                        </Typography>

                        <Link href={`/klinik/${service?.web_store_name}`} variant="body1" noWrap sx={{
                            fontWeight: 'bold', maxWidth: '80%', overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            textDecoration: 'none',
                            fontSize: '0.85rem',
                        }}

                        >
                            <span style={{ color: '#000000' }}>{`${t('at')}`}</span>
                            <span style={{ color: '#ffbc97' }}>{service.outlet_name}</span>
                        </Link>
                    </Box>

                    {matchingServiceTypes.length > 0 &&
                        <Box sx={{ height: '50%', display: 'flex', justifyContent: 'flex-end', overflow: 'hidden', width: '15%', pr: 1, pl: 0, }}>
                            <Tooltip arrow title={tooltipContent} >
                                <img
                                    src={infoIcon}
                                    alt="IconOne"
                                    style={{
                                        marginTop: 10,
                                        width: 18,
                                        height: 18,
                                    }}
                                />
                            </Tooltip>
                        </Box>
                    }
                </Stack>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', paddingBottom: 0 }}>
                    <Box sx={{ paddingLeft: 1, width: '50%' }}>
                        <Rating initialValue={service.avg_rating} fillColor='#f1a545' readonly={true} size={16} />
                        {service.avg_rating > 0 && (
                            <Typography variant="caption" sx={{ ml: 0.5, color: '#545454' }}>
                                {service.avg_rating.toFixed(1)}
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end', overflow: 'hidden', pr: 1, alignItems: 'center' }}>
                        {
                            (checkOption?.bottomText === 'Cancellation offers' || firstRow === 'Cancellation offers' || checkOption?.bottomText === 'Special offers' || firstRow === 'Special offers') ? (
                                <>
                                    <Typography variant="body1" noWrap sx={{
                                        maxWidth: '100%', overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        color: '#545454',
                                        fontSize: '16px',
                                        fontWeight: checkOption?.bottomText === 'Special offers' || firstRow === 'Special offers' ? '700' : '500',
                                        fontSize: '0.85rem',
                                        textDecoration: 'line-through'
                                    }}>
                                        {`${service?.price} kr.`}
                                    </Typography>

                                    {checkOption?.bottomText === 'Special offers' || firstRow === 'Special offers' ?
                                        <Typography variant="body1" noWrap sx={{
                                            maxWidth: '100%', overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontWeight: 700,
                                            fontSize: '1rem',
                                            ml: 1,
                                            color: '#00a518'
                                        }}>
                                            {`${Math.round(service?.special_price)} kr.`}
                                        </Typography>
                                        : ''}
                                </>

                            ) : (
                                <Typography variant="body1" noWrap sx={{
                                    maxWidth: '100%', overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 700,
                                    fontSize: '1rem',
                                    color: '#00a518'
                                }}>
                                    {`${Math.round(service?.price)} kr.`}
                                </Typography>
                            )
                        }

                    </Box>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', padding: 5 }}>
                    <Box
                        component="img"
                        src={locationIcon}
                        alt="service Image"
                        sx={{
                            width: '16px',
                            height: '16px',
                            objectFit: 'contain',
                            objectPosition: 'center',
                            display: 'block',
                        }}
                    />
                    <Typography
                        variant="caption"
                        noWrap
                        sx={{
                            paddingLeft: 0.25,
                            width: '65%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                            color: '#545454'
                        }}
                    >
                        {service?.address}
                    </Typography>

                    <Box sx={{ width: '35%', display: 'flex', justifyContent: 'flex-end', overflow: 'hidden', pr: 0 }}>
                        {
                            (checkOption?.bottomText === 'Cancellation offers' || firstRow === 'Cancellation offers') ? (
                                <Typography variant="body1" noWrap sx={{
                                    maxWidth: '100%', overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 700,
                                    fontSize: '1rem',
                                    color: '#00a518'
                                }}>
                                    {`${Math.round(service?.cancellation_offer_price)} kr.`}
                                </Typography>
                            ) : (
                                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Box
                                        component="img"
                                        src={locationIcon}
                                        alt="service Image"
                                        sx={{
                                            width: '16px',
                                            height: '16px',
                                            objectFit: 'contain',
                                            objectPosition: 'center',
                                            display: 'block',
                                            marginLeft: 0.5
                                        }}
                                    />
                                    <Typography
                                        variant="caption"
                                        noWrap
                                        sx={{
                                            paddingLeft: 0.5,
                                            maxWidth: '70%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            display: 'block',
                                            color: '#545454'
                                        }}
                                    >
                                        {service?.distance != null
                                            ? (service?.distance > 1000
                                                ? `${Math.floor(service?.distance / 1000).toFixed(1)} km`
                                                : `${Math.floor(service?.distance.toFixed(1))} m`)
                                            : ''}
                                    </Typography>
                                </div>
                            )
                        }
                        {/* {checkOption?.bottomText == 'Cancellation offers' || firstRow == 'Cancellation offers' ?
                            <Typography variant="h6" noWrap sx={{
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: '#00a518'
                            }}>
                                {`${Math.round(service?.cancellation_offer_price)} kr.`}
                            </Typography>
                            :
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: "flex-end", alignItems: 'center' }}>
                                <Box
                                    component="img"
                                    src={locationIcon}
                                    alt="service Image"
                                    sx={{
                                        width: '16px',
                                        height: '16px',
                                        objectFit: 'contain',
                                        objectPosition: 'center',
                                        display: 'block',
                                        marginLeft: 0.5
                                    }}
                                />

                                <Typography
                                    variant="caption"
                                    noWrap
                                    sx={{
                                        paddingLeft: 0.5,
                                        maxWidth: '70%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        display: 'block',
                                        color: '#545454'
                                    }}
                                >
                                    {service?.distance != null
                                        ? (service?.distance > 1000
                                            ? `${Math.floor(service?.distance / 1000)} km`
                                            : `${Math.floor(service?.distance)} m`)
                                        : ''}
                                </Typography>
                            </div>
                        } */}
                    </Box>
                </div>

                <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center' }}>
                    <Box
                        component="img"
                        src={`https://fiind-dev.s3.amazonaws.com/${service?.image}`}
                        alt="service Image"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            display: 'block',
                            backgroundColor: 'black',
                        }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            // top: 0,
                            // marginTop: '130px',
                            marginLeft: { xs: '0', sm: '0', md: '24px' },
                            width: 'auto',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            height:
                                (checkOption?.bottomText === 'Cancellation offers' || firstRow === 'Cancellation offers') ? '126px' : '128px',
                            alignSelf: 'center',
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                        }}
                    />

                    {
                        (checkOption?.bottomText === 'Cancellation offers' || firstRow === 'Cancellation offers') && (
                            <Box sx={{ position: 'absolute',top:'55%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'end',}}>
                                <Box
                                    sx={{
                                        // position: 'absolute',
                                        // top: '68%',
                                        // left: '50%',
                                        // transform: 'translate(-50%, -50%)',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        padding: '5px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100%',
                                        zIndex: 100
                                    }}
                                >
                                    <Typography variant='h6' sx={{ fontWeight: '700', zIndex: 100, color: 'white' }}>
                                        {displayText}
                                    </Typography>
                                    <Typography variant='h6' sx={{ fontWeight: '700', zIndex: 100, color: 'white' }}>
                                        {`kl. ${timeDisplay}`}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    }
                    {/* {checkOption?.bottomText == 'Cancellation offers' || firstRow == 'Cancellation offers' &&
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '65%',
                                left: '55%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                padding: '5px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '100%',
                            }}
                        >
                            <Typography sx={{ fontSize: '25px', fontWeight: '700' }}>
                                {displayText}
                            </Typography>
                            <Typography sx={{ fontSize: '25px', fontWeight: '700' }}>
                                {`kl. ${timeDisplay}`}
                            </Typography>
                        </Box>
                    } */}

                    {service?.web_store_name &&
                        <Box sx={{ position: 'absolute', zIndex: 1, width: '100%', height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'end', bottom: 5 }}>
                            <Button
                                variant="contained"
                                disableElevation
                                onClick={() => navigate(`/klinik/${service?.web_store_name}`, { state: { from: 'marketPlace', service: service } })}
                                sx={{
                                    position: 'absolute',
                                    bottom: -5,
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 1,
                                    width: '80%',
                                    borderRadius: 5,
                                    color: 'white',
                                    border: '1px solid white',
                                    backgroundColor: checkOption?.bottomText === 'Cancellation offers' || firstRow === 'Cancellation offers' || checkOption?.bottomText == "Special offers" || firstRow == 'Special offers' ? '#df013b' : theme.palette.primary.main,
                                    textTransform: 'none',
                                    fontWeight: 700,
                                    fontSize: '0.90rem',
                                    height: 30,
                                }}
                            >
                                {(checkOption?.bottomText === 'Cancellation offers' || firstRow === 'Cancellation offers') ? t('viewCancellationOffer') : t('bookService')}
                                {/* {checkOption?.bottomText == 'Cancellation offers' || firstRow == 'Cancellation offers' ? `${t('viewCancellationOffer')}` : `${t('bookService')}`} */}
                            </Button>
                        </Box>
                    }
                </Box>
            </CardContent>
        </Card>
    );
};

export default ServiceCard;